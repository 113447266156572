import React, { useState, useEffect } from "react"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { connect } from "react-redux"
import { CONTRACT_ADDRESS_RAGNAROK, CONTRACT_ADDRESS_COLLECT } from '@util/addressHelpers'
import abi_ragnarok from "@config/ABIs/abi-ragnarok.json"
import abi_collect from "@config/ABIs/abi-collect.json"
import { data } from "./data"
import "./mintpage.scss"

const MintPage = ({
  web3, walletAddress, connected
}) => {
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  })
  const [factionData, setFactionData] = useState(data)
  const [ragnarokContract, setRagnarokContract] = useState(null)
  const [isPresale, setIsPresale] = useState(true)
  const [isPending, setIsPending] = useState(false)

  useEffect(() => {
    if (connected)
      initialHandler()

    setInterval(() => {
      if (connected)
        initialHandler()
    }, 15000)
  }, [connected])

  const initialHandler = async () => {
    const _ragnarokContract = new web3.eth.Contract(abi_ragnarok, CONTRACT_ADDRESS_RAGNAROK)
    const _collectContract = new web3.eth.Contract(abi_collect, CONTRACT_ADDRESS_COLLECT)
    const _price = await _ragnarokContract.methods.price().call()
    const _isPresaleEnded = await _ragnarokContract.methods.presaleEnded().call()
    const max_per_section = await _ragnarokContract.methods.MAX_PER_FACTION().call()

    setRagnarokContract(_ragnarokContract)
    setIsPresale(!_isPresaleEnded)

    const result = []
    const valiate = []
    for (var i = 0; i < 9; i++) {
      result.push(fetchMintedAmountHandler(_ragnarokContract, i))
      valiate.push(fetchMintValidateHandler(_collectContract, i))
    }
    const factionMintedAmounts = await Promise.all(result)
    let tmp = [...data]
    factionMintedAmounts.map((amount, index) => {
      tmp[index].remain = max_per_section - Number(amount)
      tmp[index].price = index < 8 ? _price : _price * 8
      tmp[index].isSoldOut = max_per_section === Number(amount)
    })

    if(!_isPresaleEnded) {
      const validateResult = await Promise.all(valiate)
      validateResult.map((value, index) => {
        tmp[index].isAvaliable = value > 0
      })
    }

    setFactionData(tmp)
  }

  const fetchMintedAmountHandler = async (contract, faction) => {
    return await contract.methods.mintedByFaction(faction).call()
  }

  const fetchMintValidateHandler = async (contract, faction) => {
    return await contract.methods.balanceOf(walletAddress, data[faction].rune).call()
  }

  const connectHandler = () => {
    document.getElementById("btn-wallet-connect").click()
  }

  const mintHandler = async (faction, price) => {
    if (ragnarokContract === null)
      return

    if (isPresale) {
      try {
        await ragnarokContract.methods.mintPublicPresale(faction).send({ from: walletAddress, value: price })
          .on('transactionHash', (receipt) => {
            setIsPending(true)
            displayNotify("info", "Your transaction is processing...please wait.")
          })
          .on('receipt', (receipt) => {
            initialHandler()
            setIsPending(false)
            displayNotify("success", "Success! Your NFTs will be visible in your wallet and on OpenSea shortly.")
          })
          .on('error', () => {
            setIsPending(false)
            displayNotify("error", "Transaction cancelled.")
          });
      } catch (error) {
        setIsPending(false)
        displayNotify("error", "Unfortunately, your transaction has failed. Please try again.")
      }
    } else {
      try {
        await ragnarokContract.methods.mintPublicSale(faction).send({ from: walletAddress, value: price })
          .on('transactionHash', (receipt) => {
            setIsPending(true)
            displayNotify("info", "Your transaction is processing...please wait.")
          })
          .on('receipt', (receipt) => {
            initialHandler()
            setIsPending(false)
            displayNotify("success", "Success! Your NFTs will be visible in your wallet and on OpenSea shortly.")
          })
          .on('error', () => {
            setIsPending(false)
            displayNotify("error", "Transaction cancelled.")
          });
      } catch (error) {
        setIsPending(false)
        displayNotify("error", "Unfortunately, your transaction has failed. Please try again.")
      }
    }
  }

  const displayNotify = (type, content) => {
    setAlertState({
      open: true,
      message: content,
      severity: type,
    })
  }

  const FactionItem = (props) => {
    const { faction, index } = props

    return (
      <div className={`flex flex-col relative mx-2 my-3 xl:mx-10 lg:my-6 ${faction.special ? "item-heimdall" : ""}`}>
        <img src={`/mint_page/bg_${faction.name}.png`} alt={`${faction.name}_back`} className="" />
        <div className="absolute bottom-0 flex-col items-center left-0 right-0 top-0 w-full z-10 flex">
          <div className="flex flex-1 flex-col items-center">
            <div className="flex pt-4 px-4 flex-none sm:pt-8 md:pt-2 lg:pt-6 xl:pt-8">
              <div className="text-center p-2 w-1/2">
                <img src={`/mint_page/img_${faction.name}.png`} alt={faction.name} className="shadow-2xl" />
                <p className="mt-2 leading-4 2xl:text-2xl">Faction: {faction.name}</p>
              </div>
              <div className="flex items-center justify-center w-1/2">
                {faction.special ?
                  <div className="flex">
                    <img src={faction.tiles} alt="heimdall" className="" />
                  </div>
                  :
                  <div className="grid grid-cols-2 gap-y-2">
                    {faction.tiles.map((tile, idx) =>
                      <img src={tile} className="w-4/5 mx-auto" key={idx} />
                    )}
                  </div>
                }
              </div>
            </div>
            <div className="flex flex-col gap-2 md:gap-0 lg:gap-1 items-center justify-start flex-1 w-full">
              <div className="flex gap-2 text-xl xs:text-2xl sm:text-base md:text-xl lg:text-3xl xl:text-3xl 2xl:text-4xl sm:mt-0 md:mt-0 xl:pt-2">
                <p>only</p>
                <p>{faction.remain}</p>
                <p>remain!</p>
              </div>
              {connected ?
                isPending ? (
                  <button className={`bg-app-${faction.name} rounded-md text-xl tiny:text-3xl sm:text-xl md:text-lg lg:text-3xl xl:text-4xl 2xl:text-5xl lg:py-1 w-1/2 hover:scale-105 transform duration-75`}>
                    Pending ...
                  </button>
                ) : faction.isSoldOut ? (
                  <button className={`bg-app-${faction.name} rounded-md text-xl tiny:text-3xl sm:text-xl md:text-lg lg:text-3xl xl:text-4xl 2xl:text-5xl lg:py-1 w-1/2 hover:scale-105 transform duration-75`}>
                    Sold Out!
                  </button>
                ) : (
                  <button className={`bg-app-${faction.name} ${!faction.isAvaliable ? "disabled" : "hover:scale-105 transform duration-75"} rounded-md text-xl tiny:text-3xl sm:text-xl md:text-lg lg:text-3xl xl:text-4xl 2xl:text-5xl lg:py-1 w-1/2 `} disabled={!faction.isAvaliable} onClick={() => mintHandler(index, faction.price)}>
                    Mint
                  </button>
                )
                : (
                  <button className={`bg-app-${faction.name} rounded-md text-xl tiny:text-3xl sm:text-xl md:text-lg lg:text-3xl xl:text-4xl 2xl:text-5xl lg:py-1 w-1/2 hover:scale-105 transform duration-75`} onClick={() => connectHandler()}>
                    Connect
                  </button>
                )}
            </div>
          </div>
        </div>
        <div className="absolute flex gap-1 right-2 sm:right-3 md:right-2 lg:right-3 bottom-1 sm:bottom-2 md:bottom-1 tiny:text-xl xs:text-2xl sm:text-base lg:text-xl xl:text-3xl 2xl:text-4xl">
          <p className="">{faction.price / 10e17}</p>
          <p>eth</p>
        </div>
      </div>
    )
  }

  return (
    <div className="mint-container uppercase text-app-blue-50 font-staatliches flex justify-center items-center flex-col">
      <div className="container flex flex-col items-center justify-center px-4 pt-32 lg:pt-44 pb-36">
        <p className="text-4xl lg:text-6xl text-center">mint your warior!</p>
        <p className="text-center mt-6 xl:w-2/3 text-xl sm:text-2xl xl:text-3xl xl:leading-11 2xl:leading-12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-8">
          {factionData.map((faction, idx) =>
            <FactionItem faction={faction} key={idx} index={idx} />
          )}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={alertState.open}
        autoHideDuration={10000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
          className="alert-md"
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

const stateProps = (state) => ({
  web3: state.web3,
  walletAddress: state.walletAddress,
  connected: state.connected
});

export default connect(stateProps, null)(MintPage);