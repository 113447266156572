import React from 'react'
import Layout from '../components/layout/layout'
import MintPage from '../components/partials/MintPage'


const Mint = () =>{
  return(
    <Layout page="mint">
      <MintPage />
    </Layout>
  )
}

export default Mint