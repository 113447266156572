export default {
  contract_ragnarok: {
    1: '0x3d52AEe01ce3e25F528B31615892723a27b79087', // Ethereum Mainnet
    4: '0x559eb4f4b1F3E7be39822E154A5383697204DD55', // Ethereum Rinkeby
  },
  contract_collect: {
    1: '0x3e117f11E1fD875A33332f54b67e1AA64dc05d49', // Ethereum Mainnet
    4: '0x2543244507764a7b8453cE013D8D2De4Eb4bb4C4', // Ethereum Rinkeby
  },
  contract_hero: {
    1: '0x3e117f11E1fD875A33332f54b67e1AA64dc05d49', // Ethereum Mainnet
    4: '0x3dd60655a0C52EF4056c2516649088c140Ca08cF', // Ethereum Rinkeby
  },
}