export const data = [
  {
    special: false,
    name: "thor",
    remain: 123,
    price: 5 * 10e15,
    isSoldOut: false,
    isAvaliable: true,
    rune: 15,
    tiles: [
      "/mint_page/ellipse_thor.svg",
      "/mint_page/ellipse_thor.svg",
      "/mint_page/ellipse_thor.svg",
      "/mint_page/ellipse_thor.svg"
    ],
  },
  {
    special: false,
    name: "surtr",
    remain: 123,
    price: 5 * 10e15,
    isSoldOut: false,
    isAvaliable: true,
    rune: 1,
    tiles: [
      "/mint_page/ellipse_surtr.png",
      "/mint_page/ellipse_surtr.png",
      "/mint_page/ellipse_surtr.png",
      "/mint_page/ellipse_surtr.png"
    ],
  },
  {
    special: false,
    name: "odin",
    remain: 123,
    price: 5 * 10e15,
    isSoldOut: false,
    isAvaliable: true,
    rune: 9,
    tiles: [
      "/mint_page/ellipse_odin.svg",
      "/mint_page/ellipse_odin.svg",
      "/mint_page/ellipse_odin.svg",
      "/mint_page/ellipse_odin.svg"
    ],
  },
  {
    special: false,
    name: "fenrir",
    remain: 123,
    price: 5 * 10e15,
    isSoldOut: false,
    isAvaliable: true,
    rune: 7,
    tiles: [
      "/mint_page/ellipse_fenrir.svg",
      "/mint_page/ellipse_fenrir.svg",
      "/mint_page/ellipse_fenrir.svg",
      "/mint_page/ellipse_fenrir.svg"
    ],
  },
  {
    special: true,
    name: "heimdall",
    remain: 123,
    price: 6 * 10e15,
    isSoldOut: false,
    isAvaliable: true,
    rune: 5,
    tiles: "/mint_page/collection_heimdall.png"
  },
  {
    special: false,
    name: "loki",
    remain: 123,
    price: 5 * 10e15,
    isSoldOut: false,
    isAvaliable: true,
    rune: 3,
    tiles: [
      "/mint_page/ellipse_loki.svg",
      "/mint_page/ellipse_loki.svg",
      "/mint_page/ellipse_loki.svg",
      "/mint_page/ellipse_loki.svg"
    ],
  }, {
    special: false,
    name: "jormungandr",
    remain: 123,
    price: 5 * 10e15,
    isSoldOut: false,
    isAvaliable: true,
    rune: 13,
    tiles: [
      "/mint_page/ellipse_jormungandr.svg",
      "/mint_page/ellipse_jormungandr.svg",
      "/mint_page/ellipse_jormungandr.svg",
      "/mint_page/ellipse_jormungandr.svg"
    ],
  },
  {
    special: false,
    name: "ymir",
    remain: 123,
    price: 5 * 10e15,
    isSoldOut: false,
    isAvaliable: true,
    rune: 11,
    tiles: [
      "/mint_page/ellipse_ymir.svg",
      "/mint_page/ellipse_ymir.svg",
      "/mint_page/ellipse_ymir.svg",
      "/mint_page/ellipse_ymir.svg"
    ],
  },
  {
    special: false,
    name: "hel",
    remain: 123,
    price: 5 * 10e15,
    isSoldOut: false,
    isAvaliable: true,
    rune: 17,
    tiles: [
      "/mint_page/ellipse_hel.svg",
      "/mint_page/ellipse_hel.svg",
      "/mint_page/ellipse_hel.svg",
      "/mint_page/ellipse_hel.svg"
    ],
  }
];
